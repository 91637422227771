@import "../setup/settings";

@include media-breakpoint-up(md) {
  body{
      overflow: inherit !important;
  }
}

body,
.card,
.card-header,
.hero {
  background-color: var(--skin-background-color-1);
  font-family: "NotoSans-Light", serif;
  color: $black;
}

.hero {
  border-bottom-color: var(--skin-background-color-1);
}

.main-menu {
  border-top: 0.75px solid $border-palette-color;
  border-bottom: 0.75px solid $border-palette-color;
  background-color: $white;
}

.banner-color {
  background-color: var(--skin-banner-background-color-1);
}

h1.header.page-title,
h1.header.page-title::before {
  background-color: var(--skin-heading-color-1);
  color: var(--skin-heading-color-1-invert);
}

.product-tile {
  background-color: var(--skin-background-color-1);
  border: 10px solid var(--skin-border-color-1);
}

.refinements ul li button {
  color: var(--skin-primary-color-1);
}

.custom-select {
  background-color: var(--skin-selectbox-background-color-1);
  color: var(--skin-selectbox-text-color-1);
}

.price {
  color: var(--skin-price-1);
}

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
  color: black;
}

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
  color: var(--skin-selectbox-text-color-1);
}

.navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: $ebony;

  a,
  .nav-item {
    color: var(--skin-selectbox-text-color-1);
  }
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: var(--skin-menu-color-1-invert);
  color: var(--skin-menu-color-1) !important;
}
