svg {
  &.svg-icons {
    width: rem(42);
    height: rem(42);

    &.tiny {
      width: rem(10);
      height: rem(10);
    }

    &.verysmall {
      width: rem(13);
      height: rem(13);
    }

    &.small {
      width: rem(15);
      height: rem(15);
    }

    &.medium {
      width: rem(20);
      height: rem(20);
    }

    &.large {
      width: rem(24);
      height: rem(24);
    }

    &.huge {
      width: rem(30);
      height: rem(30);
    }
  }
}
