@import "bootstrap/scss/mixins/breakpoints";

h1,
.h1 {
  font-family: "NotoSans-Light", serif;
  font-weight: 400;
  letter-spacing: rem(2);
  display: block;
  margin-bottom: rem(20);
  font-size: rem(30);
  line-height: rem(36);
  @include media-breakpoint-up(sm) {
    font-size: rem(50);
    line-height: rem(56);
  }
}

h2,
.h2 {
  font-family: "NotoSans-Regular", serif;
  font-size: rem(20);
  line-height: rem(30);
  display: block;
  @include media-breakpoint-up(sm) {
    font-size: rem(32);
    line-height: rem(42);
  }
}

h3 {
  font-family: "NotoSans-Light", serif;
  font-weight: 400;
  font-size: rem(16);
  letter-spacing: rem(2);
  line-height: rem(26);
  display: block;
  margin-bottom: rem(20);
  @include media-breakpoint-up(sm) {
    font-size: rem(24);
    line-height: rem(34);
  }
}

h4 {
  font-family: "NotoSans-Light", serif;
  font-weight: 400;
  font-size: rem(20);
  line-height: rem(24);
  letter-spacing: rem(2);
  display: block;
  margin-bottom: rem(20);
  @include media-breakpoint-up(sm) {
    font-size: rem(22);
    line-height: rem(32);
  }
}

h5,
.h5 {
  font-family: "NotoSans-Regular", serif;
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(30);
  display: block;
  margin-bottom: rem(20);
  @include media-breakpoint-up(sm) {
    font-size: rem(24);
  }
}

.h6 {
  font-family: "NotoSans-Light", serif;
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(28);
  display: block;
  margin-bottom: rem(15);
  @include media-breakpoint-up(sm) {
    font-size: rem(16);
  }
}

.h7 {
  font-family: "NotoSans-LightItalic", serif;
  font-weight: 400;
  font-size: rem(16);
  line-height: rem(24);
  display: block;
  margin-bottom: rem(15);
  &-light {
    color: $mexican-red;
  }
}

.h8 {
  font-family: "NotoSans-Light", serif;
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(20);
  display: block;
  margin-bottom: rem(20);
  @include media-breakpoint-up(sm) {
    font-size: rem(16);
    line-height: rem(28);
  }
}

.h9 {
  font-family: "NotoSans-Italic", serif;
  font-size: rem(16);
  line-height: rem(18);
  display: block;
  margin-bottom: rem(15);
  @include media-breakpoint-up(sm) {
    font-size: rem(18);
    line-height: rem(30);
  }
}

.h10 {
  font-family: "NotoSans-Light", serif;
  font-weight: 400;
  font-size: rem(12);
  line-height: rem(22);
  display: block;
  margin-bottom: rem(15);
  @include media-breakpoint-up(sm) {
    font-size: rem(12);
    line-height: rem(28);
  }
}

.hero-font {
  font-family: "NotoSans-Light", serif;
  font-weight: 400;
  font-size: rem(35);
  line-height: rem(45);
  display: block;
  margin-bottom: rem(20);
  @include media-breakpoint-up(sm) {
    font-size: rem(70);
    line-height: rem(80);
  }
}

.breadcrumb-item {
  font-family: "NotoSans-Regular", serif;
  line-height: rem(11);
  & + .breadcrumb-item {
    &:before {
      color: $main-color;
    }
  }
}

@mixin no-hover-no-visited {
  text-decoration: none;
  border-bottom: none;
  &:visited,
  &:hover {
    text-decoration: none;
    border-bottom: none;
  }
}

a {
  font-family: "NotoSans-Regular", serif;
  font-weight: 400;
  font-size: rem(16);
  line-height: rem(28);
  color: $cascade;
  position: relative;
  border-bottom: 1px dotted $cascade;
  transition: all 0.2s ease-out;
  //&:visited {
  // text-decoration: none;
  // color: $medium-grey;
  // border-bottom: 1px dotted $medium-grey;
  //}
  &:hover {
    text-decoration: none;
    color: $river-bed;
    border-bottom: 1px dotted $river-bed;
  }
  &.breadcrumb-item-link {
    font-family: "NotoSans-Regular", serif;
    font-size: rem(12);
    line-height: rem(12);
    border-bottom: none;
    color: $main-color !important;
    letter-spacing: 0.02em;
    &:hover,
    &:visited {
      text-decoration: none;
      color: $hit-gray;
    }
    &.current-page {
      color: $hit-gray !important;
      &:hover,
      &:visited {
        text-decoration: none;
        color: $hit-gray;
      }
    }
  }
  &.account,
  &.wishlist,
  &.minicart-link,
  &.cc-navbar-link,
  &.cc-menu-subcategory-name,
  &.cc-menu-subcategorybis-name {
    @include no-hover-no-visited;
    color: $black;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  font-size: rem(11);
}

.menu-toggleable-left .nav-item .nav-link,
.multilevel-dropdown .dropdown-item .dropdown-link,
.footer-about-us ul li a,
.footer-customer-service ul li a,
.footer-social a,
.dropdown-country-selector .dropdown-item,
.product-tile .image-container a,
.product-tile .image-container .quickview,
.product-tile .tile-body .pdp-link a,
.product-tile .tile-body .quickview-brand,
.brand-box a,
#quickViewModal .modal-header .full-pdp-link,
#editProductModal .modal-header .full-pdp-link,
#editWishlistProductModal .modal-header .full-pdp-link,
.carousel .carousel-control-prev,
.carousel .carousel-control-next,
#quickViewModal .modal-body .on-sale-wrapper + a,
#editProductModal .modal-body .on-sale-wrapper + a,
#editWishlistProductModal .modal-body .on-sale-wrapper + a,
.product-detail:not(.product-quickview) .primary-images + div .brand-block a,
#accordion-product-info h4 a,
#accordion-product-detail h4 a,
#quickViewModal .modal-body .body-info a,
#editProductModal .modal-body .body-info a,
#editWishlistProductModal .modal-body .body-info a,
.page[data-action="Cart-Show"]
  .top-part
  ~ .product-info
  .item-attributes
  .product-edit.product-move
  a.move.special,
.suggestions .container#search-results li.items .item a,
#content-search-results-pane #content-search-results .card-header h4 a,
.product-tile .swatches a, 
.footer-contact-us a {
  @include no-hover-no-visited;
}

.container .breadcrumb .breadcrumb-item a,
.product-detail.product-wrapper
  .cc-image-product-mobile
  ~ #accordion-product
  .card
  .card-header
  a {
  @include media-breakpoint-down(sm) {
    @include no-hover-no-visited;
  }
}

.strong {
  font-weight: 700;
}

.promotion-label {
  background-color: $river-bed !important;
  border: 1px solid $river-bed !important;
  color: $white;
  display: inline-block;
  font-family: "NotoSans-Regular", serif;
  font-size: rem(14);
  height: rem(30);
  line-height: rem(36);
  padding: 0 rem(15);
  text-transform: uppercase;
}

.sale-label {
  border: 1px solid $cascade;
  background-color: $cascade;
  color: $white;
  display: inline-block;
  font-family: "NotoSans-Regular", serif;
  font-size: rem(14);
  height: rem(30);
  line-height: rem(36);
  padding: 0 rem(15);
  text-transform: uppercase;
}
