@font-face {
  font-family: "NotoSans-Light";
  src: url("../../fonts/NotoSans-Light.eot");
  src: url("../../fonts/NotoSans-Light.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/NotoSans-Light.svg#NotoSans-Light") format("svg"),
    url("../../fonts/NotoSans-Light.ttf") format("truetype"),
    url("../../fonts/NotoSans-Light.woff") format("woff"),
    url("../../fonts/NotoSans-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans-LightItalic";
  src: url("../../fonts/NotoSans-LightItalic.eot");
  src: url("../../fonts/NotoSans-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/NotoSans-LightItalic.svg#NotoSans-LightItalic") format("svg"),
    url("../../fonts/NotoSans-LightItalic.ttf") format("truetype"),
    url("../../fonts/NotoSans-LightItalic.woff") format("woff"),
    url("../../fonts/NotoSans-LightItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url("../../fonts/NotoSans-Regular.eot");
  src: url("../../fonts/NotoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/NotoSans-Regular.svg#NotoSans-Regular") format("svg"),
    url("../../fonts/NotoSans-Regular.ttf") format("truetype"),
    url("../../fonts/NotoSans-Regular.woff") format("woff"),
    url("../../fonts/NotoSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans-Italic";
  src: url("../../fonts/NotoSans-Italic.eot");
  src: url("../../fonts/NotoSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/NotoSans-Italic.svg#NotoSans-Italic") format("svg"),
    url("../../fonts/NotoSans-Italic.ttf") format("truetype"),
    url("../../fonts/NotoSans-Italic.woff") format("woff"),
    url("../../fonts/NotoSans-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}