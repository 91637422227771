// FORMS
.form-control {
  font-weight: 400;
  font-size: rem(16);
  letter-spacing: rem(0.32px);
  line-height: rem(28);
  padding: 0 30px;
  color: $black;
  border: 1px solid $form-grey;
  border-radius: 0;
  height: rem(73);
  &::placeholder {
    color: $medium-grey;
    opacity: 1;
    transition: color 0.2s ease-out;
  }
  &:focus {
    color: $black;
    box-shadow: none;
    border: 1px solid $form-grey;
    &::placeholder {
      color: $black;
    }
  }
  @include media-breakpoint-up(md) {
    height: 4rem;
  }
  &.is-invalid {
    border: 1px solid $mexican-red;
    box-shadow: none;
    background-image: none;
    &:focus {
      border: 1px solid $mexican-red;
      box-shadow: none;
      background-image: none;
    }
  }
}

// RADIO BUTTONS
.custom-control-input[type="radio"] {
  width: rem(19);
  height: rem(19);
  & ~.custom-control-label {
    &:before {
      width: rem(19);
      height: rem(19);
      border: 1px solid $border-grey;
      background: $white;
    }
    &:after {
      width: rem(19);
      height: rem(19);
    }
  }
  &:checked {
    & ~.custom-control-label {
      &:after {
        background-size: 100%;
        background: url("../../images/radioCheck.svg"), $white;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid $border-grey;
        border-radius: 50px;
      }
    }
  }
}

.custom-checkbox {
    padding-left: rem(29);

    .custom-control-label::before {
        top: 5px;
        left: rem(-29);
        border-radius: 50px;
        border: 1px solid $medium-grey;
        width: rem(17);
        height: rem(17);
        background-size: 130% 2px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../images/checkBox-no.svg");
        margin-top: -1px;
        padding: rem(8.5);
    }
    .custom-control-input:checked { 
      ~ .custom-control-label::before {
        color: transparent;
        border-color: transparent;
        background-color: transparent;
        background-image: none;
      }
      ~ .custom-control-label::after {
        top: 5px;
        left: rem(-29);
        width: rem(17);
        height: rem(17);
        background-size: 60%;
        background-position: center;
        background-image: url("../../images/checkBox-yes.svg");
        margin-top: -1px;   
        border-radius: 50px;
        border: 1px solid $medium-grey;
        padding: rem(8.5);
      }
    }
}

// SEARCH
.search-field {
  font-family: "NotoSans-Regular", sans-serif;
  font-size: rem(16);
  line-height: rem(28);
  padding: 0 rem(40) 0 rem(10);
  border: 1px solid $border-palette-color;
  border-radius: rem(30);
  height: rem(52);
  background-color: $white;
  color: $tundora;
  &::placeholder {
    opacity: 1;
    transition: color 0.2s ease-out;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid $border-palette-color;
    color: $black;
  }
  @include media-breakpoint-up(sm) {
    padding: 0 rem(80) 0 rem(25);
  }
  @include media-breakpoint-down(sm) {
    height: rem(35);
    font-size: rem(12);
  }
}

.search-field ~ .reset-button {
  top: 50%;
  transform: translateY(-55%);
  right: rem(60);
  outline: none;
  @include media-breakpoint-down(lg) {
    right: rem(70);
  }
}

.search-field ~ .fa-search {
  width: rem(40);
  height: rem(52);
  top: 0;
  right: 0;
  outline: none;
  z-index: 5;
  border: none;
  &:before {
    display: none;
  }
  &:after {
    content: "";
    position: absolute;
    background: url("../../images/lens.svg");
    background-repeat: no-repeat;
    width: rem(18);
    height: rem(18);
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    @include media-breakpoint-down(sm) {
      transform: translate(50%, -50%) scale(0.7);
    }
  }
  @include media-breakpoint-up(sm) {
    width: rem(70);
  }
}

// SELECT
select.form-control {
  padding: 0 30px;
  &.size,
  &.sort-order,
  &.select-size,
  &.select-accessorySize {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("data:image/svg+xml,%3Csvg data-name='Componente 20 – 106' xmlns='http://www.w3.org/2000/svg' width='21.625' height='12.203'%3E%3Cpath data-name='Tracciato 104' d='M10.813 12.203L.288 1.678a.983.983 0 111.39-1.39l9.135 9.134L19.948.288a.983.983 0 111.39 1.39z' fill='%23939393'/%3E%3C/svg%3E"),
      transparent;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    option {
      background: $white;
      &.lasts-items {
        color: $medium-grey;
      }
      &.sold-out {
        color: $mexican-red;
      }
    }
  }
  &.quantity {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.445' height='13.524'%3E%3Cpath data-name='Tracciato 254' d='M0 5.532L4.723 0l4.722 5.532M0 7.991l4.723 5.532 4.722-5.532' fill='gray' opacity='.627'/%3E%3C/svg%3E"),
    #F3F3F3;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;
    font-weight: 400;
    font-size: 18px;
    border: 1px solid $light-grey;
    letter-spacing: rem(0.32px);
    line-height: 1;
  }
}

.quantity-input {
  background-image: none;
  background-color: #ededed;
  text-align: center;
  font-weight: 600;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  position: relative;
}

.quantity-cart {
  position: relative;
  .quantity-input {
    height: rem(73);
  }
  
  .quantity-subtract,
  .quantity-add {
    left: 0px;
  }

  .quantity-add {
    left: auto;
    right: 0px;
  }
}
.quantity {
  position: relative;
}
.quantity-subtract,
.quantity-add {
  left: 10px;
  top: 50%;
  padding: 20px 20px 20px 20px;
  position: absolute;
  z-index: 2;
  font-weight: 600;
  font-size: 18px;
  transform: translateY(-50%);
  cursor: pointer;
}
.quantity-add {
  left: auto;
  right: 10px;
}

@media (max-width: 768px) {
  .quantity-cart {
    .form-control {
          padding-left: 0px;
    }
  }
  .quantity-subtract,
  .quantity-add,
  .quantity-input {
      font-weight: 400;
  }

  .quantity-subtract {
      padding: 15px 15px 15px 15px;
      left: 15px;
  }

  .quantity-add {
      right: 5px;
      padding: 15px 15px 15px 15px;
  }

  .quantity {
      padding-right: 0;
  }
}

.custom-select {
  width: 100%;

  .custom-select-text {
    display: inline;
    padding-left: rem(20);
    color: $medium-grey;
    font-weight: 400 !important;

    &.sold-out {
      text-transform: uppercase;
      color: $mexican-red;
    }
  }

  .dropdown-toggle {
    color: $main-color;
    font-size: rem(16);
    text-align: left;
    padding: 0 rem(40) 0 rem(27);
    display: block;
    border: 1px solid $form-grey;
    border-radius: 0;
    background: $white;
    box-shadow: none;
    position: relative;
    height: rem(73);

    @include media-breakpoint-down(sm) {
      height: rem(60);
      padding: 0 rem(40) 0 rem(23);
      font-size: rem(14);
    }

    &:after {
      position: absolute;
      top: 50%;
      right: rem(15);
      z-index: 2;
      font-size: rem(25);
      margin: 0;
      margin-top: rem(-3);
      padding: 0;
      color: $main-color;
    }

    .filter-option {
      font: inherit;
      color: inherit;
      text-align: left;
      white-space: normal;
      font-style: normal;
      height: auto;

      .cc-select-name {
        font-style: normal;
      }
    }

    &:active,
    &:focus {
      color: $main-color !important;
      background: $white !important;
      border-color: $form-grey !important;
    }

    &.btn {
      font-family: "NotoSans-Light", sans-serif;
      text-transform: capitalize;
      color: $main-color !important;
      border-color: $form-grey !important;
      background: url("data:image/svg+xml,%3Csvg data-name='Componente 20 – 106' xmlns='http://www.w3.org/2000/svg' width='21.625' height='12.203'%3E%3Cpath data-name='Tracciato 104' d='M10.813 12.203L.288 1.678a.983.983 0 111.39-1.39l9.135 9.134L19.948.288a.983.983 0 111.39 1.39z' fill='%23939393'/%3E%3C/svg%3E"),
        $white !important;
      background-repeat: no-repeat !important;
      background-position-x: 95% !important;
      background-position-y: 50% !important;

      @include media-breakpoint-down(sm) {
        background-size: 15px !important;
        background-position-x: 90% !important;
      }

      &:after {
        display: none;
      }
    }

    &.cc-disabled,
    &.disabled {
      background: $white !important;
      border-color: $form-grey !important;
      color: $medium-grey !important;
      cursor: default;
      opacity: 1;
    }
  }

  .dropdown-menu {
    border: 1px solid $form-grey;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: -1px 0;
    max-width: 100%;

    &.inner {
      border: none;
    }

    li {
      display: block;
      width: 100%;
      border-bottom: 1px solid $form-grey;

      &:last-child {
        border-bottom: none;
      }

      a {
        display: block;
        padding: rem(22) rem(27);
        text-align: left;
        text-decoration: none;
        border: none;

        @include media-breakpoint-down(sm) {
          height: rem(60);
          padding: rem(16) rem(23);
          font-size: rem(14);
        }

        &.active {
          font-weight: 700;
          color: $main-color;
        }

        &.disabled {
          color: #ccc;
        }

        &:hover {
          background: $light-grey !important;
        }
      }

      &.selected {
        a {
          background: $white;
          color: $main-color;
        }
      }
    }
  }
}

.bootstrap-select .dropdown-menu.show {
  transform: unset !important;
  will-change: unset !important;
  top: unset !important;
}