$main-color: #333333 !default;
$border-grey: #5b5c60 !default;
$search-grey: #f7f7f7 !default;
$border-palette-color: #797979 !default;
$form-grey: #d3d3d3;
$cart-bullet: #c79452;
$promo-banner: #b3c7d3;

$medium-wood: #a78e5f !default;
$panda: #535038 !default;
$white: #ffffff !default;
$black: #000000 !default;
$ebony: #2e3138 !default;
$dark-grey: #797979 !default;
$medium-grey: #9f9f9f !default;
$light-grey: #ededed !default;
$soldout-grey: #c4c4c4 !default;
$tundora: #444444 !default;
$hit-gray: #A3AFB7 !default;
$rolling-stone: #707980 !default;


// NEW COLORS
$river-bed: #4C5760 !default;
$cascade: #93A8AC !default;
$akaroa: #D7CEB2 !default;
$gray-olive: #A59E8C !default;
$ironside-gray: #66635B !default;
$mexican-red: #8F4F4F !default;
$black: #000000 !default;
$tundora: #444444 !default;
$dark-grey: #797979 !default;
$medium-grey: #9f9f9f !default;
$light-grey: #ededed !default;
$concrete: #F3F3F3 !default;
$white: #ffffff !default;


.bck-hit-gray {
  background-color: $hit-gray;
}
.hit-gray {
  color: $hit-gray;
}
.bck-rolling-stone {
  background-color: $rolling-stone;
}
.rolling-stone {
  color: $rolling-stone;
}

// ------


.bck-main-color {
  background-color: $main-color;
}
.main-color {
  color: $main-color;
}

.bck-medium-wood {
  background-color: $medium-wood;
}
.medium-wood {
  color: $medium-wood;
}

.bck-panda {
  background-color: $panda;
}
.panda {
  color: $panda;
}

.bck-mexican-red {
  background-color: $mexican-red;
}
.mexican-red {
  color: $mexican-red;
}

.bck-white {
  background-color: $white;
}
.white {
  color: $white;
}

.bck-black {
  background-color: $black;
}
.black {
  color: $black;
}

.bck-ebony {
  background-color: $ebony;
}
.ebony {
  color: $ebony;
}

.bck-dark-grey {
  background-color: $dark-grey;
}
.dark-grey {
  color: $dark-grey;
}

.bck-medium-grey {
  background-color: $medium-grey;
}
.medium-grey {
  color: $medium-grey;
}

.bck-light-grey {
  background-color: $light-grey;
}
.light-grey {
  color: $light-grey;
}

.bck-river-bed {
  background-color: $river-bed;
}
.river-bed {
  color: $river-bed;
}

.bck-cascade {
  background-color: $cascade;
}
.cascade {
  color: $cascade;
}

.bck-akaroa {
  background-color: $akaroa;
}
.akaroa {
  color: $akaroa;
}

.bck-gray-olive {
  background-color: $gray-olive;
}
.gray-olive {
  color: $gray-olive;
}

.bck-ironside-gray {
  background-color: $ironside-gray;
}
.ironside-gray {
  color: $ironside-gray;
}

.bck-tundora {
  background-color: $tundora;
}
.tundora {
  color: $tundora;
}

.bck-concrete {
  background-color: $concrete;
}
.concrete {
  color: $concrete;
}